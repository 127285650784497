import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./function/i18n";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// import React from "react";
// import { createRoot } from "react-dom";
// import "./index.css";
// import App from "./App";
// import { BrowserRouter } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";
// import "./function/i18n";

// const root = createRoot(document.getElementById("root"));

// if (process.env.NODE_ENV === "production") {
//   // Якщо ви в режимі продакшн, використовуйте React Snap
//   import("./App").then((module) => {
//     root.render(
//       <React.StrictMode>
//         <BrowserRouter>
//           <module.default />
//         </BrowserRouter>
//       </React.StrictMode>
//     );
//   });
// } else {
//   // Якщо ви в режимі розробки, просто рендеріть компонент
//   root.render(
//     <React.StrictMode>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </React.StrictMode>
//   );
// }

// // Вимірюйте продуктивність вашого додатку, якщо потрібно
// reportWebVitals();
